<template>
  <div class="wrapp-product-media" :class="{ 'has-video' : hasVideo !== '' }">
    <img
      v-lazy="imgObj"
      :alt="alt"
      :title="alt"
      :key="imgObj.src"
      :class="imageClass"
      :style="styleImage"
    />
    <div v-if="hasVideo !== ''" class="wrapp-product-media__video">
      <div class="product-video">
        <video autoplay playsinline loop muted class="aqz-video">
          <source :src="hasVideo" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import { getThumbnailPath } from '@/helpers'
export default {
  props: {
    imgPath: {
      type: String,
      default: ''
    },
    hasVideo: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    styleImage: {
      type: Object,
      default () { return {} }
    },
    imageClass: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'Image'
    },
    changeAble: {
      type: Boolean,
      default: () => false
    },
    defaultLoading: {
      default: () => require('@/assets/image/dump-square.png')
    }
  },
  computed: {
    imgObj () {
      return {
        src: getThumbnailPath(this.imgPath, this.size),
        error: (this.size === 'LG' ? require('@/assets/image/dump.png') : this.defaultLoading),
        loading: (this.size === 'LG' ? require('@/assets/image/dump.png') : this.defaultLoading)
      }
    },
    src () {
      return this.changeAble ? this.imgObj.src : this.imgObj.loading
    }
  }
}
</script>
